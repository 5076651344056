import { template as template_125d668bcb5446f3b92fcd21bfa45d1a } from "@ember/template-compiler";
const FKLabel = template_125d668bcb5446f3b92fcd21bfa45d1a(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
