import { template as template_b9a50437e8ab41078f5166fd17a1f2a1 } from "@ember/template-compiler";
const FKText = template_b9a50437e8ab41078f5166fd17a1f2a1(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
