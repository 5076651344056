import { template as template_d8fd8d67a8a44dba99ae4fed0030a0d3 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import DButton from "discourse/components/d-button";
export default class PostMenuShowMoreButton extends Component {
    static shouldRender(args) {
        return args.state.collapsedButtons.length && args.state.collapsed;
    }
    static{
        template_d8fd8d67a8a44dba99ae4fed0030a0d3(`
    <DButton
      class="post-action-menu__show-more show-more-actions"
      ...attributes
      @action={{@buttonActions.showMoreActions}}
      @icon="ellipsis-h"
      @title="show_more"
    />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
